import {
    SITE_TASKS,
    SITE_TASKS_KEYS,
    PAGE_TASKS,
    PAGE_TASKS_KEYS,
    TASK_TYPE,
    ONGOING_TASKS,
    KEYWORD_DEPENDANT_TASKS,
    TOTAL_TASKS_IN_A_PAGE
} from './constants';
import { TaskItemKeyT } from "../types";
import { setDataInSessionStorage } from "../../../Window/windowStorageUtils";
import dayjs from "../../../../../../../wbtgen/src/dayjs";

export const
    setMarketgooTaskConfig = (taskId: string, payload?: string) => {
        setDataInSessionStorage({
            key: taskId,
            payload
        });
    };

export const getTaskByTaskId = (tasks: Record<string, any>, taskId: TaskItemKeyT) => tasks.filter(task => task.task_id === taskId);

export const getTaskKeys = (type) => {
    if (type === TASK_TYPE.site) {
        return Object.keys(SITE_TASKS_KEYS) as (TaskItemKeyT)[];
    }

    return Object.keys(PAGE_TASKS_KEYS) as (keyof typeof PAGE_TASKS_KEYS)[];
};

export const getTasks = (type) => {
    if (type === TASK_TYPE.site) return SITE_TASKS;
    if (type === TASK_TYPE.page) return PAGE_TASKS;

    return ONGOING_TASKS;
};

export const getPreferencesKeyForPageTask = (pageId: string, taskId: string) => `${pageId}_${taskId}`;

export const calculateScore = (tasks: Array<Record<string, any>>, backLinksScore: number) => {
    const pageTasks = tasks.filter(task => task.type === TASK_TYPE.page);
    const siteTasks = tasks.filter(task => task.type === TASK_TYPE.site);
    const pagesTasksNotCompleted = pageTasks.filter(task => !task.passed);
    const siteTasksCompleted = siteTasks.filter(task => task.passed);

    const nonCompletedPagesSubTasks = pagesTasksNotCompleted.reduce((sum, pageTask) => {
        sum += pageTask.data.tasks.filter(subTask => !subTask.passed).length;

        if (!pageTask.data.page.keyword) {
            sum += KEYWORD_DEPENDANT_TASKS;
        }

        return sum;
    }, 0);

    const totalPagesSubTasks = pageTasks.length * TOTAL_TASKS_IN_A_PAGE;
    const completedPagesSubTasks = totalPagesSubTasks - nonCompletedPagesSubTasks;
    const pagesScore = completedPagesSubTasks / totalPagesSubTasks;

    // weightage: 40% page tasks, 40% site tasks, 20% backLinks
    const score = (pagesScore || 0) * 40 + (siteTasksCompleted.length / siteTasks.length) * 40 + backLinksScore;

    return Math.ceil(score);
};

type GetKeywordTasksType = {
    keyword: string;
    title: string;
    fullUrl: string;
    isHomePage: boolean;
};

export const getKeywordTasks = ({ keyword, title, fullUrl, isHomePage }: GetKeywordTasksType) => {
    const keywordTasks = [{
        id: PAGE_TASKS_KEYS.keyword,
        passed: Boolean(keyword),
    }];

    if (keyword) {
        const _url = fullUrl.split("/").splice(1).join('/').toLowerCase();
        const _title = title.toLowerCase();
        const _words = keyword.toLowerCase().split(/\s+/g);

        if (!isHomePage) {
            keywordTasks.push({
                id: PAGE_TASKS_KEYS.keywordinurl,
                passed: _words.every(word => _url.includes(word)),
            });
        }

        keywordTasks.push({
            id: PAGE_TASKS_KEYS.keywordintitle,
            passed: _words.every(word => _title.includes(word)),
        });
    }

    return keywordTasks;
};

const DAYS_GAP = 1;

export const getNewScoreHistory = (score: number, marketgooPreferences: Record<string, any>): Array<{ score: number, time: number }> | null => {
    if (score && marketgooPreferences) {
        const newScore = { score, time: Date.now() };

        if (!marketgooPreferences.scoreHistory) {
            const initialScore = { score: -1, time: Date.now() };
            return [initialScore, newScore];
        }

        const [previousScore, currentScore] = marketgooPreferences.scoreHistory;
        const differenceInDays = dayjs().diff(dayjs(currentScore.time), 'day');

        if (differenceInDays >= DAYS_GAP) {
            return [currentScore, newScore];
        } else if (score !== currentScore.score) {
            return [previousScore, { score, time: currentScore.time }];
        }
    }
    return null;
};

export enum SCORE_TRENDS {
    UNCHANGED = 'UNCHANGED',
    INCREASED = 'INCREASED',
    DECREASED = 'DECREASED'
}

export const getScoreTrend = (marketgooPreferences: Record<string, any> = {}): SCORE_TRENDS | null | undefined => {
    if (!marketgooPreferences?.scoreHistory) return null;

    const [previousScore, currentScore] = marketgooPreferences.scoreHistory;

    if (previousScore.score < 0) return null;

    const diff = currentScore.score - previousScore.score;

    if (diff === 0) {
        return SCORE_TRENDS.UNCHANGED;
    }

    return diff > 0 ? SCORE_TRENDS.INCREASED : SCORE_TRENDS.DECREASED;
};

export const getSelectedTabIndex = (taskId) => {
    if (PAGE_TASKS_KEYS[taskId]) return 1;

    return 0;
};
